import React from "react"

import Layout from "../components/layout-pt"
import SEO from "../components/seo"

const PricesPage = () => (
  <Layout>
    <SEO title="Preços" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Preços</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column" />
        <div class="column is-5">
          <table class="table is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th />
                <th>Preço (€/noite)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adulto</td>
                <td>4,50€</td>
              </tr>
              <tr>
                <td>Criança (5 a 12 anos)</td>
                <td>3,50€</td>
              </tr>
              <tr>
                <td>Criança (menos de 5)</td>
                <td>grátis</td>
              </tr>
              <tr>
                <td>Animais</td>
                <td>1,00€</td>
              </tr>
              <tr>
                <td>Tenda (até 6m / mais de 6m)</td>
                <td>5,00€ / 6,00€</td>
              </tr>
              <tr>
                <td>Caravana (até 5m / mais de 5m)</td>
                <td>5,00€ / 6,00€</td>
              </tr>
              <tr>
                <td>Autocaravana (até 5m / mais de 5m)</td>
                <td>6,00€ / 7,00€</td>
              </tr>
              <tr>
                <td>Carro</td>
                <td>3,50€</td>
              </tr>
              <tr>
                <td>Mota</td>
                <td>2,50€</td>
              </tr>
              <tr>
                <td>Bicicleta</td>
                <td>free</td>
              </tr>
              <tr>
                <td>Máquina de lavar a roupa</td>
                <td>5,00€/lavagem</td>
              </tr>
              <tr>
                <td>Eletricidade (disponível em alguns alvéolos)</td>
                <td>4,00€</td>
              </tr>
            </tbody>
          </table>
          <div class="content">
            <p>
              A utilização de todos os equipamentos (churrasqueira, mesas,
              frigorífico, arca frigorífica, jogos, etc.) é gratuita.
            </p>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </Layout>
)

export default PricesPage
