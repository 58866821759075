import React, { Component } from "react"
import NavBar from "./navbar-pt"
import Footer from "./footer"
import '@fortawesome/fontawesome-svg-core/styles.css'
import "./layout.scss"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = { isActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive })
  }

  render() {
    return (
      <div>
        <link href="svg-with-js.css" rel="stylesheet"></link>
        <NavBar
          isActive={this.state.isActive}
          toggleNavbar={() => this.toggleNavbar()}
        />
        <div>{this.props.children}</div>
        <Footer />
      </div>
    )
  }
}

export default Layout
